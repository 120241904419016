<template>
  <span v-if="shortname" rel="profile" class="menu-nav menu-nav__profile" onclick="location.href = '/profile'">
    <span :data-intro="intro">
      {{ lochello }},&nbsp;<b>{{ shortname }}</b>
      <div class="image-wrap">
        <v-lazy-image v-if="theimage" :src="theimage" />
      </div>
    </span>
    <ul>
      <li>
        <a href="#" @click="logoutUser">{{ loclogout }}</a>
      </li>
    </ul>
  </span>
  <div v-else class="loading loading--invert"><div></div><div></div><div></div></div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'userbutton',
  props: [
    'lochello',
    'loclogout',
    'intro'
  ],
  data() {
    return {
      returnUrl: window.location.href,
      name: null,
      photo: null
    }
  },
  watch: {
    token() {
      this.updateUser()
    }
  },
  computed: {
    ...mapGetters('profile', [
      'theimage',
      'shortname'
    ]),
    ...mapGetters('msalStore', [
      'token'
    ])
  },
  methods: {
    ...mapActions('profile', [
      'changeimage',
      'changename',
      'changeprofile'
    ]),
    updateUser() {
      const self = this
      this.$msalAPI.getDataAPI('_api/SP.UserProfiles.PeopleManager/GetMyProperties').then((me) => {
        self.name = me.data.d.DisplayName
        self.changename(self.name)
        self.changeprofile(me.data.d)

        if (me.data.d.PictureUrl) {
          const uri = `_api/web/GetFileByServerRelativeUrl('/User%20Photos/${me.data.d.PictureUrl.split('User%20Photos/')[1].split('?')[0].replace('MThumb', 'LThumb')}')/$value`
          this.$msalAPI.getFileAPI(uri, { encoding: null, responseType: 'arraybuffer' })
            .then(result => {
              self.photo = `data:image/jpeg;base64,${new Buffer(result.data).toString('base64')}`
              self.changeimage(self.photo)
            })
        }
      })
    },
    logoutUser(e) {
      e.preventDefault()
      localStorage.removeItem('msal.image')
      this.$msal.logout()
      e.stopPropagation()
    }
  }
}
</script>
