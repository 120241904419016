<template>
  <div class="container-fluid wall carousel-wall" id="carousel-wall">
    <div class="container title__container">
      <div class="row justify-content-center">
          <div class="col title-wall">
              <slot name="title"></slot>
              <p v-if="locintro">{{locintro}}</p>
              <hr class="line line--ligher">
          </div>
      </div>
  </div>
    <div class="row">
      <div class="col">
        
        <div v-if="addForm" class="container dc">
          <form method="post" enctype="multipart/form-data" novalidate>
            <h2>{{ locadd }}</h2>
            <div class="row">
              <div class="col">
                <div class="form-label">{{ locmsg }}</div><span v-if="invalid.indexOf('message') > -1" class="invalid">*</span>
                <textarea name="message" v-model.trim="form.message" :maxlength="maxLenght"></textarea>
                <span>{{ maxLenghtMessage }}: {{ countChars }}</span>
              </div>
            </div>
            <div class="wall__actions--form">
              <button class="button" @click.prevent="saveItem()" :disabled="saving">{{ locsend }}</button>
              <a href="#" class="wall__add" @click.prevent="cancelAddItem()">{{ loccancel }}</a>
            </div>
          </form>
        </div>

        <div v-if="!isItemsLoading && !addForm && items.length === 0" class="wall__empty">
          {{ locempty }}
        </div>
        <div v-if="iswidget" class="wall-container">        
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="item in items" :key="item.ID">
                <div class="carousel-wall-item">
                  <div class="carousel-wall-item__quote">
                    <div class="carousel-wall-item__text"><span>&#147;</span>{{ item.Message }}<span>&#148;</span></div>  
                    <like-button 
                      :likesettings="likeSettings" 
                      :numlike="item.NumLikes.toString()" 
                      :userlike="item.UserLiked.toString()" 
                      :idnoticia="item.ID.toString()" 
                      :tenant="tenant">
                    </like-button>
                  </div>
                  <a class="carousel-wall-item__profile" :href="'/profile?id=' + item.Account">
                    <div class="carousel-wall-item__photo">
                      <v-lazy-image v-if="pictures[item.Account]" :src="pictures[item.Account]"/>
                      <v-lazy-image v-else src="/assets/intranet/Images/Profile.svg"/>
                    </div>
                    <div class="carousel-wall-item__info" v-if="profiles[item.Account]">
                      <div><b>{{ profiles[item.Account].DisplayName }}</b></div>
                      <div>{{ $utils.getProp(profiles[item.Account], 'Department') }}</div>
                      <div>{{ $utils.getProp(profiles[item.Account], 'Office') }}</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination d-none"></div>

            <!-- Add Arrows -->
            <div v-if="items.length > 1" class="swiper-button-next"></div>
            <div v-if="items.length > 1" class="swiper-button-prev"></div>            
          </div>
          <div :class="`wall__actions${iswidget ? '--widget' : ''}`" v-if="!addForm">
            <a href="#" class="button wall__add" @click.prevent="addItem">{{ locadd }}</a>
            <a v-if="iswidget" href="./thanks" class="button wall__add">{{ locmore }}</a>
          </div>
        </div>
        <div v-else class="wall__container">
          <div class="wall__item" v-for="item in items" :key="item.ID">
            <div class="carousel-wall-item__quote">
              <div v-if="!item.Approved" class="wall__approval">
                {{ locapproval }}
                <button v-if="canedit" class="button" @click.prevent="approveAd(item.ID, pageid)">{{ locapprove }}</button>
              </div>
              <div class="carousel-wall-item__text"><span>&#147;</span>{{ item.Message }}<span>&#148;</span></div>   
              <div class="wall__delete" v-if="item.CanEdit || canedit">
                <a href="#" class="button" @click.prevent="deleteAd(item.ID, pageid)">{{ locdelete }}</a>
              </div>
              <like-button :postapi="likeSettings.UrlPost.toString()" :getlikesapi="likeSettings.UrlGetLikes.toString()" :numlike="item.NumLikes.toString()" :userlike="item.UserLiked.toString()" :idnoticia="item.ID.toString()" :tenant="tenant">
                <span class="lbl-like" slot="lblLike">{{likeSettings.LikeText}}</span>
                <span slot="youStatus">{{likeSettings.YouLikeStatus}}</span>
                <span slot="likeStatus">{{likeSettings.LikeStatus}}</span>
              </like-button>
            </div>
            <a class="carousel-wall-item__profile" :href="'/profile?id=' + item.Account">
              <div  v-if="pictures[item.Account]" class="carousel-wall-item__photo">
                <v-lazy-image :src="pictures[item.Account]"/>
              </div>
              <div class="carousel-wall-item__info" v-if="profiles[item.Account]">
                <div><b>{{ profiles[item.Account].DisplayName }}</b></div>
                <div>{{ $utils.getProp(profiles[item.Account], 'Department') }}</div>
                <div>{{ $utils.getProp(profiles[item.Account], 'Office') }}</div>
              </div>
            </a>
          </div>
          <div v-if="items.length === (page * pagesize)" class="wall__container-wrapper-button">
            <div class="wall__more">
              <button class="button" @click="loadMore()">{{ locmore }}</button>
            </div>
          </div>
          
        </div>

        <div v-if="isItemsLoading" class="loading__container">
          <div class="loading loading--invert"><div></div><div></div><div></div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

import API from "./api"
import forEach from "lodash/forEach"
import Swiper from 'swiper'
import Masonry from 'masonry-layout'
import { mapGetters } from 'vuex'
const jquery = require('jquery')
let resizeTimer

export default {
  name: "wall",
  props: [
    'baseurl', 'tenant', 'canedit', 'widget', 'locadd', 'locmsg', 'locsend',
    'locdelete', 'locconfirm', 'locapproval', 'locapprove', 'loccancel', 'pageid',
    'locempty', 'locmore', 'size', 'locintro', 'likesettings', 'maxLenght', 'maxLenghtMessage'
  ],
  data: function() {
    return {
      profiles: {},
      pictures: {},
      iswidget: this.widget === 'true',
      addForm: false,
      items: [],
      isItemsLoading: false,
      form: {
        message: null
      },
      invalid: [],
      pagesize: parseInt(this.size, 10),
      page: 1,
      likeSettings: JSON.parse(this.likesettings)
    }
  },
  computed: {
    ...mapGetters('profile', [
      'theimage',
      'fullname',
      'profile'
    ]),
    countChars() {
      return this.form.message != null ? (this.maxLenght - this.form.message.length) : this.maxLenght
    }
  },
  watch:{
    $route (to, from){
      this.addForm = to.query && to.query.add
    },
    addForm (value){
      if(value){
        setTimeout(() => {
          document.getElementById('carousel-wall').scrollIntoView()
        }, 200)        
      }
    }
  },
  mounted: function() {
    this.loadItems()
  },
  updated: function() {
    this.applySwipper()
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        this.resizeText()
      }, 250)      
    })
    this.resizeText()
  },
  methods: {
    applySwipper() {
      if(this.iswidget)
        window.wallSwiper = new Swiper('.carousel-wall .swiper-container', {
          slidesPerView: 'auto',
          centeredSlides: true,
          loop: false,
          observer: true,
          threshold: 10,
          pagination: {
            el: '.carousel-wall .swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.carousel-wall .swiper-button-next',
            prevEl: '.carousel-wall .swiper-button-prev',
          }
        })
      
      else
        window.wallContainer = new Masonry('.wall__container', { })
    },

    loadItems() {
      
      this.isItemsLoading = true
      this.addForm = this.$route.query && this.$route.query.add
      
      API.wall(this.baseurl, this.pageid, this.pagesize, this.page).then((resp) => {

        this.isItemsLoading = false

        if (!resp) return
        
        this.items = resp.map(item => {
          item.Account = item.Author.split("\\")[1]
          return item
        })

        this.items.forEach(item => {
          let email = item.AuthorEmail || `${item.Account}@${this.tenant}`
          this.$msalAPI.getDataAPI(`_api/sp.userprofiles.peoplemanager/getpropertiesfor(@v)?@v='i%3A0%23.f%7Cmembership%7C${email}'`)
            .then((profile) => {
              this.$set(this.profiles, item.Account, profile.data.d)
            })

            if(!this.pictures.hasOwnProperty(item.Account))
              this.$msalAPI.getFileAPI(`_api/web/GetFileByServerRelativeUrl('/User%20Photos/Profile Pictures/${item.Account.replace(/\./ig, "_")}_${this.$msal.account.userName.split('@')[1].replace(/\./ig, "_")}_LThumb.jpg')/$value`, { encoding: null, responseType: "arraybuffer" })
                .then(resp => {
                  const image = `data:image/jpeg;base64,${new Buffer(resp.data).toString('base64')}`
                  this.$set(this.pictures, item.Account, image)
                }).catch(err => {
                  this.$set(this.pictures, item.Account, null)
                })

                var elements = document.getElementsByClassName('.carousel-wall-item__text')
          })
      })
    },

    addItem() {
      this.invalid = []
      this.form = {
        message: null
      }
      this.$router.push({ query: { add: true }})
    },
    cancelAddItem() {
      this.$router.push({ query: null })
    },
    changeSlider(resp) {
      resp.Item.Account = resp.Item.Author.split("\\")[1]

      this.$set(this.profiles, resp.Item.Author, this.profile)
      this.$set(this.pictures, resp.Item.Author, this.theimage)
      this.items.unshift(resp.Item)

      this.resizeText()
    },
    saveItem() {
      const formData = new FormData()

      if(!this.form.message)
        this.invalid.push('message')
      else
        this.invalid = this.invalid.filter(i => i !== 'message')
      
      formData.append('message', this.form.message)

      if(this.invalid.length === 0) {
        this.saving = true

        API.add(this.baseurl, formData).then(resp => {
          this.saving = false

          if(!this.iswidget) {
            window.scrollTo(0,0)
          }

          this.cancelAddItem()
          if(resp.error){
            alert(resp.error);
            location.reload();
            return;
          }
          this.changeSlider(resp)
        }).catch(err => {
          this.saving = false
        })        
      }
    },

    deleteAd(id) {
      if(confirm(this.locconfirm))
        API.delete(this.baseurl, id, this.pageid).then(() => {
          this.loadItems()
          this.$router.push({ query: null })
        })
    },

    approveAd(id) {
      API.approve(this.baseurl, id, this.pageid).then(() => {
        this.loadItems()
        this.$router.push({ query: null })
      })
    },

    loadMore() {
      this.page = this.page + 1
      this.loadItems()
    },
    resizeText () {
      var elements = jquery('.carousel-wall-item__text')
      if(elements.length < 0) return
      
      for(let i = 0; i < elements.length; i++){
        let element = elements[i]
        let text = this.cropWidgetText(element.innerText, 220, '...').replace(/[\“\”]/g, '')
        jquery(element)
          .contents()
          .filter(function() { return this.nodeType === 3}) //nodetype === 3 = text node
          .each(function(i, item) { item.nodeValue = text })

        // var fontSize = 100
        // jquery(element).css('font-size', fontSize)

        // //diminui a fonte até o texto encaixar no container
        // while(element.scrollWidth > element.offsetWidth || element.scrollHeight > element.offsetHeight) {
        //   fontSize = (parseFloat(fontSize) * 0.95) + 'px'
        //   jquery(element).css('font-size', fontSize)
        // }
      }
    },
    cropWidgetText(text, maxSize, stringAfter) {
      if(this.iswidget){
        return text.length > maxSize ? text.substr(0, maxSize) + (stringAfter || '') : text
      }
      return text
    }
  }
}
</script>